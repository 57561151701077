export const RECURSOS_TYPES = {
  GET_RECURSOS: "GET_RECURSOS",
  GET_RECURSOS_SUCCESS: "GET_RECURSOS_SUCCESS",
  GET_RECURSOS_FAILURE: "GET_RECURSOS_FAILURE",
  GET_ONE_RECURSO: "GET_ONE_RECURSO",
  GET_ONE_RECURSO_SUCCESS: "GET_ONE_RECURSO_SUCCESS",
  GET_ONE_RECURSO_FAILURE: "GET_ONE_RECURSO_FAILURE",
}

export const AUTH_TYPES = {
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
}
