import api from '../api';

const AuthService = {
    login : (data) => {
        return api.post(`/google/token`, data)
    },
    getDataUser: ()=>{
      return api.get(`/user`)
    }
}

export {AuthService as default}

