import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  useHistory,
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const BarraBusqueda = ({ className, ...props }) => {
  const classes = useStyles();
  const query = useQuery();
  const history=useHistory()

  const {filters}=props

  const levels=filters.find((e)=>e.name === 'level').items
  const modalities=filters.find((e)=>e.name === 'modality').items
  return (
    <Box mt={3}>
      <Card color="#eeeeee">
        <CardContent>
          <Formik
            initialValues={{
              title: query.get('title') ? query.get('title'):'',
              description: query.get('description') ? query.get('description'):'',
              level: query.get('level') ? query.get('level') : '',
              modality: query.get('modality') ? query.get('modality') : ''
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string(),
              description: Yup.string(),
              level:Yup.string(),
              modality:Yup.string(),
            })}
            onSubmit={async (data) => {
              query.set('title', data.title)
              query.set('description', data.description)
              query.set('level', data.level)
              query.set('modality', data.modality)
              history.push(`?${query.toString()}`)
            }}
            onReset={(data, actions)=>{
              actions.setValues({
                title:'',
                description:'',
                level:'',
                modality:''
              })
              query.set('title', "")
              query.set('description', "")
              query.set('level', "")
              query.set('modality', "")
              history.push(`?${query.toString()}`)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              isSubmitting,
              touched,
              values
            }) => (
              <Form className={classes.root} noValidate autoComplete="off">
                <Grid container>
                  <Grid xs={12} md={3} className="p-2" item>
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      fullWidth
                      label="Título"
                      name="title"
                      variant="outlined"
                      type="input"
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="p-2">
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      fullWidth
                      label="Descripción"
                      name="description"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="p-2" >
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="level-label" style={{backgroundColor:"#fff", padding:3}}>Nivel</InputLabel>
                    <Select
                      labelId="level-label"
                      value={values.level}
                      onChange={handleChange}
                      name="level"
                    >
                      <MenuItem value="">
                        <em>Seleccione un nivel</em>
                      </MenuItem>
                      {
                        levels.map(e=><MenuItem value={e.id}>{e.title}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} className="p-2" >
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="level-modality" style={{backgroundColor:"#fff", padding:3}}>Modalidad</InputLabel>
                    <Select
                      labelId="level-modality"
                      value={values.modality}
                      onChange={handleChange}
                      name="modality"
                    >
                      <MenuItem value="">
                        <em>Seleccione una modalidad</em>
                      </MenuItem>
                      {
                        modalities.map(e=><MenuItem value={e.id}>{e.title}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} className="pt-2 pl-2 text-right">
                    <Button
                      className={classes.importButton}
                      onClick={handleReset}
                    >
                      Limpiar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BarraBusqueda;
