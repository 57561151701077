import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { routes } from 'src/routesService';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  }
}));

const RecursoCard = ({ className, recurso, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          mb={3}
        >
          <Avatar
            alt={recurso.image_alt_text ? recurso.image_alt_text : recurso.title}
            src={recurso.image}
            style={{ width: "75%", height: "75%" }}
            variant="rounded"
          />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {recurso.title}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {recurso.description}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid
          container
          justify="center"
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              <strong>Nivel: </strong>{recurso.level}
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              <strong>Modalidad: </strong>{recurso.modality}
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              <strong>Descargas hechas por mí: </strong>{recurso.downloads}
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              <strong>Descargas hechas por la comunidad: </strong>{recurso.total_downloads}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box flexGrow={1} />
            <Divider />
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <Link to={routes.detalleRecurso.path.replace(':recursoId', recurso.id)}>
              <Button
                fullWidth
                size="medium"
                variant="contained"
                color="primary"
              // className="mt-2"
              >
                Ver Detalle
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default RecursoCard;
