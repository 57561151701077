import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import './assets/css/all.css'

import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/assets/theme';
import { NotificationContainer } from 'react-notifications';
import { Switch, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <NotificationContainer />
      <Switch>
        <Route path="/" component={MainLayout} />
      </Switch>
    </ThemeProvider>
  );
};

export default App;
