import {
  AUTH_TYPES
} from 'src/actions/types';

const INIT_STATE = {
  data: null,
  loading: false
}
export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case AUTH_TYPES.GET_USER:
          return {
              ...state,
              loading: true
          }
      case AUTH_TYPES.GET_USER_SUCCESS:
          return {
              ...state,
              data: action.payload,
              loading: false
          }
      case AUTH_TYPES.GET_USER_FAILURE:
          return {
              ...state,
              data:null,
              loading: false
          }

      default:
          return { ...state }
  }
}
