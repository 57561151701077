import api from '../api';

const RecursosService = {
    getRecursos : (page, filters) => {
        // substring a filters porque siempre viene "?nombre_del_filtro=valor_del_filtro"
        return api.get(`/resource?page=${page}&${filters.substring(1)}`)
    },
    getOneRecurso : (id) => {
        return api.get(`/resource/${id}`)
    },
}

export {RecursosService as default}

