/* eslint-disable linebreak-style */
import React from 'react';
import { Redirect } from 'react-router-dom';
import NotFoundView from 'src/components/errors/NotFoundView';
import HomeContainer from 'src/containers/HomeContainer';
import RecursosContainer from 'src/containers/RecursosContainer';
import ForbiddenView from 'src/components/errors/ForbiddenView';

export const routes = {
  recursos: { path: '/recursos', element: RecursosContainer },
  detalleRecurso: { path: '/recursos/:recursoId', element: RecursosContainer },

  "404": { path: '/404', element: NotFoundView },
  "403": { path: '/403', element: ForbiddenView },
  inicio: { path: '/', element: HomeContainer },
  "error": { path: '/*', element: () => <Redirect to="/404" /> },
}
