import React, { Component } from 'react';
import Loader from "react-loader-spinner";

class Loading extends Component {
  render() {
    const style = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: this.props.fullWidth ? '100vw' : 'auto',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#F4F6F8'
    };
    return (
      <div style={style}>
        <Loader type="ThreeDots" color="#ccc" height={80} width={80} />
      </div>
    );
  }
}

export default Loading;
