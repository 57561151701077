import React from 'react';
import {
  Button,
  Container,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

import GoogleIcon from 'src/assets/icons/Google'
import Page from 'src/components/Page';
import { routes } from 'src/routesService';

import imageLogin from 'src/assets/img/background.jpg'
const redTheme = createMuiTheme({ palette: { primary: red } })
const Home = (props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return (
    <Page
      className={"root-pages"}
      title="Ingreso"
      style={{paddingTop:0}}
    >
      <img alt="Logo Login" src={imageLogin} className="img-fluid mb-3" style={{maxHeight:350, width:"100%", objectFit:'cover'}}></img>
      <Container maxWidth="sm">
        {
          !localStorage.getItem('access_token') ?
            <MuiThemeProvider theme={redTheme}>
              <a href={`https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&scope=openid profile email&response_type=code&access_type=offline&approval_prompt=force`}>
                <Button
                  fullWidth
                  startIcon={<GoogleIcon />}
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={urlParams.get('code') ? true : false}
                >
                  Ingresar con Google
            </Button>
              </a>
            </MuiThemeProvider>
            :
            <Link to={routes.recursos.path}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className="mt-2"
              >
                Recursos
          </Button>
            </Link>
        }
      </Container>
    </Page>
  );
};

export default Home;
