import { RECURSOS_TYPES } from './types'
import RecursosService from '../services/RecursosService'
import { showDeveloperMessageConsole } from 'src/helpers/random';

export const getAllRecursos = (page=1, filters='') => async dispatch => {
  dispatch({
    type: RECURSOS_TYPES.GET_RECURSOS
  })
  try {
    const result = await RecursosService.getRecursos(page, filters);
    dispatch({
      type: RECURSOS_TYPES.GET_RECURSOS_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: RECURSOS_TYPES.GET_RECURSOS_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener los recursos: ", error)
  }
}

export const getOneRecurso = (id) => async dispatch => {
  dispatch({
    type: RECURSOS_TYPES.GET_ONE_RECURSO
  })
  try {
    const result=await RecursosService.getOneRecurso(id);
    dispatch({
      type: RECURSOS_TYPES.GET_ONE_RECURSO_SUCCESS,
      payload:result.data
    })
  } catch (error) {
    dispatch({
      type: RECURSOS_TYPES.GET_ONE_RECURSO_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener un recurso: ", error)
  }
}

