import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { directDownload } from 'src/helpers/random';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  }
}));

const CardDetalle = ({ className, recurso, ...rest }) => {
  const classes = useStyles();

  const [loading, setloading] = useState(false)
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          mb={3}
        >
          <Avatar
            alt={recurso.image_alt_text ? recurso.image_alt_text : recurso.title}
            src={recurso.image}
            style={{ width: "75%", height: "75%" }}
            variant="rounded"
          />
        </Box>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid
          container
          justify="center"
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              color="textPrimary"
              variant="body1"
              style={{fontSize:14}}
            >
              <strong>Archivo: </strong>{recurso.filename}
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{fontSize:14}}
            >
              <strong>Nivel: </strong>{recurso.level}
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{fontSize:14}}
            >
              <strong>Modalidad: </strong>{recurso.modality}
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{fontSize:14}}
            >
              <strong>Descargas hechas por mí: </strong>{recurso.downloads}
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{fontSize:14}}
            >
              <strong>Descargas hechas por la comunidad: </strong>{recurso.total_downloads}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box flexGrow={1} />
            <Divider />
          </Grid>
          <Grid
            item
          >
            {/* <a href={recurso.filepath} target="_blank" rel="noopener noreferrer">
              <Button
                fullWidth
                size="small"
                variant="contained"
              className="mb-1"
              >
                Ver
              </Button>
            </a> */}
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                directDownload(recurso.filepath, recurso.file_alt_text ? `${recurso.file_alt_text}.pdf` : recurso.filename, setloading, rest.handleGetOneRecurso)
              }}
            >
              Descargar
              </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default CardDetalle;
