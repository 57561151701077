import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';

import Pagination from 'react-js-pagination'

import Page from 'src/components/Page';
import BarraBusqueda from './barraBusqueda';
import Loading from '../shared/loading';
import RecursoCard from './RecursoCard';


const useStyles = makeStyles((theme) => ({
  recursoCard: {
    height: '100%'
  }
}));

const Recursos = (props) => {
  const { recursos, loading } = props

  const classes = useStyles()
  return (
    <Page
      className={"root-pages"}
      title="Recursos"
    >
      <Container maxWidth={false}>
        {
          recursos === null
            ?
            <Loading />
            :
            <>
              <BarraBusqueda filters={recursos.filters} />
              {
                loading
                  ?
                  <Loading />
                  :
                  <Box
                    className="mt-4">
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid item xs={12} className="text-center">
                        <Typography
                          display="inline"
                          variant="h3"
                          align="center"
                        >
                          Listado de Recursos
                  </Typography>
                      </Grid>
                      {recursos.data.map((recurso) => (
                        <Grid
                          item
                          key={recurso.id}
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <RecursoCard
                            className={classes.recursoCard}
                            recurso={recurso}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      {
                        recursos.data.length > 0 ?
                          <Pagination
                            activePage={recursos.current_page}
                            itemsCountPerPage={recursos.per_page}
                            totalItemsCount={recursos.total}
                            onChange={(value) => {
                              props.handleGetRecursos(value)
                            }}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination mt-4 justify-center"
                          />
                          :
                          <div className="mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <p>No se han encontrado resultados para su búsqueda.</p>
                          </div>
                      }
                    </Grid>
                  </Box>
              }
            </>
        }
      </Container>
    </Page>
  );
};

export default Recursos;
