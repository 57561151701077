import React from 'react';
import img from '../../assets/img/logo_argentina-blanco.svg';

const Logo = (props) => {
  return (
    <img
      alt="Logo Argentina"
      src={img}
      className="logo-jm"
      {...props}
    />
  );
};

export default Logo;
