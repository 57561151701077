import { combineReducers } from 'redux';
import authReducer from './authReducer';
import recursosReducer from './recursosReducer';

const rootReducer = combineReducers({
  recursos: recursosReducer,
  user: authReducer
});

export default rootReducer;
