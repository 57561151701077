import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import Recursos from '../components/recursos/recursos';
import DetalleRecurso from '../components/recursos/detalle/detalle';

import {
    getAllRecursos,
    getOneRecurso
} from '../actions/recursosActions'
import { routes } from 'src/routesService';

const RecursosContainer = props => {
    const { location, match, history, recursos } = props;

    const handleGetRecursos=(page)=>{
      props.getAllRecursos(page, location.search)
    }

    const handleGetOneRecurso=()=>{
      props.getOneRecurso(match.params.recursoId)
    }
    
    useEffect(() => {
      if(match.path === routes.recursos.path){
        props.getAllRecursos(1, location.search)
      }else if(match.path === routes.detalleRecurso.path){
        props.getOneRecurso(match.params.recursoId)
      }
      // eslint-disable-next-line
    }, [match.path, location.search, match.params.recursoId])
    
    if (match.path === routes.detalleRecurso.path) {
        return (
            <DetalleRecurso            
                location={location}
                history={history}
                match={match}
                recurso={recursos.detalle}
                handleGetOneRecurso={handleGetOneRecurso}
            />
        )
    }
    return (
        <Recursos
            location={location}
            history={history}
            match={match}
            recursos={recursos.all}
            loading={recursos.loading}
            handleGetRecursos={handleGetRecursos}
        />
    );
}

const mapStateToProps = ({ recursos }) => {
    return {
        recursos
    };
};

const mapDispatchToProps = {
    getAllRecursos,
    getOneRecurso
}

export default connect(mapStateToProps, mapDispatchToProps)(RecursosContainer);
