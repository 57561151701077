import React, { useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'

import Home from 'src/components/home/home'
import { routes } from 'src/routesService'
import AuthService from 'src/services/AuthService'
import {
  getDataUser
} from '../actions/authActions'

const HomeContainer = (props) => {
  const {history}=props

  const handleRedirect = async () => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      let code = urlParams.get('code'); // Recuperar del request / url

      if(code){
        const result=await AuthService.login({
          'client_id': process.env.REACT_APP_GOOGLE_CLIENT_ID,
          'code' : code,
          'redirect_uri': process.env.REACT_APP_GOOGLE_REDIRECT_URI,
          'grant_type' : 'authorization_code',
        })
        localStorage.setItem('access_token', result.data.access_token)
        localStorage.setItem('refresh_token', result.data.refresh_token)
        localStorage.setItem('expires_at', Date.now()+(result.data.expires_in*1000))
        props.getDataUser()
        history.push(routes.recursos.path)
      }
    } catch (error) {
      NotificationManager.error('Error al ingresar con Google')
    }
  }

  useEffect(() => {
    handleRedirect()
    // eslint-disable-next-line
  }, [])

  return (
    <Home />
  )
}

const mapStateToProps = ({ user }) => {
  return {
      user
  };
};

const mapDispatchToProps = {
  getDataUser
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
