import { AUTH_TYPES } from "./types"
import AuthService from "../services/AuthService"
// import { refreshTokenUser } from "../helpers/authHelper";
// import { NotificationManager } from 'react-notifications';
import { showDeveloperMessageConsole } from "src/helpers/random";

export const getDataUser = () => async dispatch => {
  dispatch({
    type: AUTH_TYPES.GET_USER
  })
  try {
    const result = await AuthService.getDataUser();
    dispatch({
      type: AUTH_TYPES.GET_USER_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.GET_USER_FAILURE
    })
    showDeveloperMessageConsole('Error al obtener la info del usuario', error)
    // if(error.response && error.response.status === 401){
    //         let aux =await refreshTokenUser(AuthService.getAuthUser);
    //         if(aux !== null){
    //             const result = await AuthService.getAuthUser();
    //             dispatch({
    //                 type: GET_USER_SUCCESS,
    //                 payload: result.data
    //             })
    //         }else{
    //             dispatch({
    //                 type: GET_USER_FAILURE
    //             })
    //         }
    //     }else{
    //         dispatch({
    //             type: GET_USER_FAILURE
    //         })
    //     }
  }
}
