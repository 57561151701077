import React from 'react';
import {
  Box,
  Container, Divider, Grid, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import parse from 'html-react-parser';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Page from 'src/components/Page';
import Loading from 'src/components/shared/loading';
import CardDetalle from './card';
import { Link } from 'react-router-dom';
import { routes } from 'src/routesService';

const useStyles = makeStyles((theme) => ({
  recursoCard: {
    // height: '100%'
  }
}));

const DetalleRecurso = (props) => {
  const { recurso } = props
  const classes = useStyles()

  if (recurso === null) {
    return <Loading />
  }

  return (
    <Page
      className={"root-pages"}
      title="Detalle"
    >
      <Container maxWidth={false}>
        <Link to={routes.recursos.path}>
          <IconButton aria-label="delete" size="small">
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </Link>

        <Box
          className="mt-4">

          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12} sm={3}>
              <CardDetalle recurso={recurso} className={classes.recursoCard} handleGetOneRecurso={props.handleGetOneRecurso} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container>
                <Grid item>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h2"
                  >
                    {recurso.title}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="body2"
                  >
                    {recurso.description}
                  </Typography>
                  <Divider className="mt-3 mb-3" />
                  <div>
                    {parse(recurso.body)}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default DetalleRecurso;
