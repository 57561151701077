import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Typography,
  Box
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';

import Logo from 'src/components/shared/Logo';
import { routes } from 'src/routesService';

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64,
    justifyContent: "space-between"
  }
}));

const TopBar = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const history = useHistory()

  const logout = () => {
    localStorage.removeItem("access_token")
    history.push(routes.inicio.path)
  }
  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        {
          localStorage.getItem('access_token') &&
          <Box display="flex" alignItems="center">
          {
            user.data &&
            <Typography>
              {user.data.name} {user.data.lastname}
            </Typography>
          }
          <IconButton color="inherit" title="Salir" onClick={() => {
            logout()
          }}>
            <InputIcon />
          </IconButton>
          </Box>
        }

      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
