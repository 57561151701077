import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'src/routesService';
import {
  getDataUser
} from 'src/actions/authActions'
import { connect } from 'react-redux';
import Loading from 'src/components/shared/loading';
import argentinaPresidencia from 'src/assets/img/logo_argentina_presidencia.svg'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const MainLayout = (props) => {
  const classes = useStyles();
  const { location, history, user } = props

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      props.getDataUser();
    } else if (location.pathname !== "/") {
      history.push("/")
    }
    // eslint-disable-next-line
  }, [])

  if ((localStorage.getItem("access_token") && user.data === null && location.pathname !== "/")) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <TopBar user={user} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Switch>
              {
                Object.keys(routes).map((key, index) => {
                  return (
                    <Route key={index} path={`${routes[key].path}`} component={routes[key].element} exact />
                  )
                })
              }
            </Switch>
            <footer className="footer mt-auto pb-0 pt-3">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-6 offset-lg-0 col-10 offset-1">
                    <img className="logos-footer" alt="Logo Footer" src={argentinaPresidencia} height="100px" />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <p className="text-muted small"> Los contenidos de Argentina.gob.ar están licenciados bajo <a href="https://creativecommons.org/licenses/by/2.5/ar/" target="_blank" rel="noopener noreferrer">Creative Commons Reconocimiento 2.5 Argentina License</a></p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user
  };
};

const mapDispatchToProps = {
  getDataUser
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
