import {
    RECURSOS_TYPES
} from '../actions/types';

const INIT_STATE = {
    all: null,
    detalle:null,
    loading:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case RECURSOS_TYPES.GET_RECURSOS:
            return{
                ...state,
                detalle:null,
                loading: true
            }
        
        case RECURSOS_TYPES.GET_RECURSOS_SUCCESS:
            return{
                ...state,
                all: action.payload,
                loading: false
            }
        
        case RECURSOS_TYPES.GET_RECURSOS_FAILURE:
            return{
                ...state,
                loading: false
            }
        case RECURSOS_TYPES.GET_ONE_RECURSO:
            return{
                ...state,
                loading: true
            }
        
        case RECURSOS_TYPES.GET_ONE_RECURSO_SUCCESS:
            return{
                ...state,
                detalle: action.payload,
                loading: false
            }
            
        case RECURSOS_TYPES.GET_ONE_RECURSO_FAILURE:
            return{
                ...state,
                loading: false
            }
        default: return { ...state };
    }
}
