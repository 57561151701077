import moment from 'moment';
import { NotificationManager } from 'react-notifications';

export const convertDate = (date, currentFormat, incomingFormat) => {
  moment.locale("es", {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),

  })
  return moment(date, currentFormat).format(incomingFormat)
}

/**
 * Function to return message console for only developers
 */
export function showDeveloperMessageConsole(message, vars = null) {
  if (process.env.NODE_ENV === "development") {
    if (vars) {
      console.log(message, vars)
    } else {
      console.log(message)
    }

  }

}

/**
 * Function to return data for selects
 */
export function getDataFormatedForSelects(data, propertyId, propertyLabel) {
  let aux = []
  //eslint-disable-next-line
  data.map(d => {
    aux.push({
      label: d[propertyLabel],
      value: d[propertyId]
    })
  })
  return aux

}


/**
 * Function to return ids 
 */
export function getIdsFromData(data,) {
  let aux = []
  //eslint-disable-next-line
  data.map(d => {
    aux.push(d.id)
  })
  return aux

}

export function getDataSelected(lista, search, value, display) {
  const obj = lista.find(o => o[search] === value);
  let a = '';
  let b = '';
  if (obj) {
    a = obj[search];
    b = obj[display];
  }
  return { value: a, label: b };
}

export const directDownload = (fileUrl, name, setLoading, exectFunction) => {
  setLoading(true)
  fetch(`${fileUrl}?${Date.now()}`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
  })
    .then((response) => {
      if (response.status === 403) {
        setLoading(false)
        NotificationManager.error('Ha excedido el numero de descargas máximas.')
      } else {
        return response.blob()
      }
    })
    .then((blob) => {
      if (blob) {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        exectFunction()  
        setLoading(false)
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }

    }).catch(error => {
      setLoading(false)

    });
}
