import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension'
import reducers from '../reducers';

export function configureStore(initialState) {

    const store =  createStore(
        reducers,
        initialState,
        process.env.NODE_ENV === "development" ?
        composeWithDevTools(
            applyMiddleware(thunkMiddleware),
        ) :
        compose(applyMiddleware(thunkMiddleware))
    );

    // if (module.hot) {
    //     // Enable Webpack hot module replacement for reducers
    //     module.hot.accept('../reducers/index', () => {
    //         const nextRootReducer = require('../reducers/index');
    //         store.replaceReducer(nextRootReducer);
    //     });
    // }

    return store;
}
